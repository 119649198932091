import React from 'react'
import { ContentBlock, FormBlock } from 'components'
import { Link } from 'react-router'
import { PARTNER_PORTAL_ROUTE } from 'config'

function ForgotPasswordConfirmation() {
  return (
    <ContentBlock>
      <FormBlock>
        <div className="form-block-inner">
          <h2>A password reset email is on the way.</h2>
          <p>
            If your email is in our system, a password reset link is on its way
            from no-reply@artlookmap.com.
          </p>
          <p>
            <strong>Don't see it yet?</strong>
          </p>
          <p>
            Check your spam folder. It can sometimes take a few minutes to get
            to your inbox.
          </p>
          <Link
            className="button-primary"
            to={PARTNER_PORTAL_ROUTE + '/sign-in'}
          >
            Back to Sign In
          </Link>
        </div>
      </FormBlock>
    </ContentBlock>
  )
}

export default ForgotPasswordConfirmation
