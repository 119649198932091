import React from 'react'

function ScholarshipsQuestionExample({ community }) {
  const isMichigan = community.subdomain === 'michigan'

  return (
    <div>
      <p>
        <strong>Example:</strong>
      </p>
      <div className="form-table">
        <div className="form-table-heading">
          <div className="form-table-row">
            <div className="form-table-head">Discipline</div>
            <div className="form-table-head">Number of Students</div>
            {!isMichigan && (
              <div className="form-table-head">Total Scholarship Amount</div>
            )}
          </div>
        </div>
        <div className="form-table-body">
          <div className="form-table-row">
            <div className="form-table-cell">
              <i>Music</i>
            </div>
            <div className="form-table-cell">
              <i>7</i>
            </div>
            {!isMichigan && (
              <div className="form-table-cell">
                <i>$2,500.00</i>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default React.memo(ScholarshipsQuestionExample)
