import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { lpForm } from 'lp-form'
import { Field, FieldArray, propTypes as formPropTypes } from 'redux-form'
import {
  Select,
  Input,
  SubmitButton,
  ButtonArea,
  MaskedInput,
} from 'lp-components'
import { ReadOnlyCellInput, HiddenLabel } from 'components'
import * as Types from 'types'
import { startCase } from 'lodash'
import { serializeOptions } from 'utils'
import classnames from 'classnames'

const propTypes = {
  ...formPropTypes,
  disciplines: PropTypes.arrayOf(Types.discipline).isRequired,
  editing: PropTypes.bool.isRequired,
  setEditing: PropTypes.func.isRequired,
}

const defaultProps = {}

function validatePresence(value) {
  if (!value) return 'Value required'
}

function ScholarshipFieldRow({
  field,
  disciplines,
  editing,
  onDelete,
  change,
  community,
}) {
  const fieldNameLabel = startCase(field)
  const amountFieldName = field + '.amountDollars'
  const isMichigan = community.subdomain === 'michigan'

  return (
    <div className="form-table-row">
      <div className="form-table-cell">
        <Field
          name={field + '.disciplineId'}
          validate={validatePresence}
          placeholder="Select"
          labelComponent={HiddenLabel}
          label={fieldNameLabel + ' discipline'}
          options={serializeOptions(disciplines)}
          component={editing ? Select : ReadOnlyCellInput}
        />
      </div>
      <div className="form-table-cell">
        <Field
          name={field + '.studentsCount'}
          validate={validatePresence}
          labelComponent={HiddenLabel}
          label={fieldNameLabel + ' number of students'}
          type="number"
          min={0}
          component={editing ? Input : ReadOnlyCellInput}
        />
      </div>
      {!isMichigan && (
        <div className="form-table-cell">
          <Field
            name={amountFieldName}
            validate={validatePresence}
            labelComponent={HiddenLabel}
            label={fieldNameLabel + ' total amount in dollars'}
            maskOptions={{
              numeral: true,
              numeralPositiveOnly: true,
              prefix: '$',
              rawValueTrimPrefix: true,
            }}
            onChange={(e) => {
              // return the raw value without mask formatting
              e.preventDefault()
              change(amountFieldName, e.target.rawValue)
            }}
            onBlur={(e) => e.preventDefault()}
            disabled={!editing}
            component={MaskedInput}
            className={classnames({ 'read-only-disabled-input': !editing })}
          />
        </div>
      )}

      <div className="form-table-cell">
        {editing && (
          <button
            type="button"
            className="remove-row"
            onClick={onDelete}
            aria-label={'Remove ' + fieldNameLabel}
          >
            ×
          </button>
        )}
      </div>
    </div>
  )
}

function ScholarshipFields({
  fields,
  disciplines,
  editing,
  change,
  community,
}) {
  const isMichigan = community.subdomain === 'michigan'
  return (
    <React.Fragment>
      <div className="scrollable-table">
        <div className="form-table">
          {fields.length > 0 && (
            <div className="form-table-heading">
              <div className="form-table-row">
                <div className="form-table-head">Discipline</div>
                <div className="form-table-head">Number of Students</div>
                {!isMichigan && (
                  <div className="form-table-head">
                    Total Scholarship Amount
                  </div>
                )}
              </div>
            </div>
          )}
          <div className="form-table-body">
            {fields.map((field, i) => (
              <ScholarshipFieldRow
                key={i}
                field={field}
                disciplines={disciplines}
                editing={editing}
                onDelete={() => fields.remove(i)}
                change={change}
                community={community}
              />
            ))}
          </div>
        </div>
      </div>
      {editing && (
        <button
          type="button"
          className="link-black"
          onClick={() => fields.push({})}
        >
          <span aria-hidden>+</span> Add Discipline
        </button>
      )}
    </React.Fragment>
  )
}

function ScholarshipsForm({
  disciplines,
  editing,
  setEditing,
  handleSubmit,
  submitting,
  reset,
  change,
  community,
}) {
  const showEditButton = !editing

  return (
    <form onSubmit={handleSubmit} noValidate disabled={!editing}>
      <FieldArray
        name="scholarships"
        editing={editing}
        setEditing={setEditing}
        disciplines={disciplines}
        community={community}
        component={ScholarshipFields}
        change={change}
      />
      <ButtonArea>
        {/* Submit button is required when a form exists */}
        <div className={classnames({ 'visually-hidden': !editing })}>
          <SubmitButton {...{ submitting }} disabled={!editing}>
            Save Response
          </SubmitButton>
          <button
            type="button"
            className="button-grey-light"
            onClick={() => {
              reset()
              setEditing(false)
            }}
            disabled={!editing}
          >
            Cancel
          </button>
        </div>
        {showEditButton && (
          <button
            type="button"
            className="button-grey-light"
            onClick={() => setEditing(true)}
          >
            Edit Response
          </button>
        )}
      </ButtonArea>
    </form>
  )
}

ScholarshipsForm.propTypes = propTypes
ScholarshipsForm.defaultProps = defaultProps

export default compose(
  lpForm({
    name: `school-scholarships`,
    enableReinitialize: true,
  })
)(ScholarshipsForm)
